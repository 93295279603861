import '../styles/globals.scss';
import 'antd/dist/reset.css';

import { ConfigProvider } from 'antd';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { ReactElement, ReactNode } from 'react';

import CommonLayout from '@/components/layouts';
import { ProvideAuth } from '@/hooks/useAuth';
import { configParams } from '@/utils/configParams';
export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function EventWiz({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout =
    Component.getLayout ?? ((page: ReactElement) => <CommonLayout>{page}</CommonLayout>);
  return (
    <ProvideAuth>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#49A5EF',
            borderRadius: 5,
          },
        }}
      >
        {getLayout(<Component {...pageProps} />)}
      </ConfigProvider>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${configParams.GA_MEASUREMENT_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${configParams.GA_MEASUREMENT_ID}');
  `}
      </Script>
    </ProvideAuth>
  );
}

export default EventWiz;
