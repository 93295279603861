import cx from 'classnames';
import React from 'react';

import styles from './text-input.module.scss';

export type HTMLInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix' | 'size'>;

export interface TextInputProps extends HTMLInputProps {
  className?: string;

  size?: 'lg' | 'sm';
  prefix?: React.ReactNode | string;
  suffix?: string | React.ReactNode;
}

const TextInput = React.forwardRef<HTMLInputElement, React.PropsWithChildren<TextInputProps>>(
  (props, ref) => {
    const { className, prefix, size, ...rest } = props;

    const _getAddons = (obj: string | React.ReactNode) => {
      if (!obj) return null;
      if (typeof obj === 'string' || typeof obj === 'number') {
        return <span className="input-group-text">{obj}</span>;
      }
      return <div className="input-group-text">{obj}</div>;
    };

    return (
      <div
        className={cx(
          'input-group',
          styles.container,
          className,
          { 'input-group-sm': size === 'sm' },
          { 'input-group-lg': size === 'lg' }
        )}
      >
        {_getAddons(prefix)}
        <input ref={ref} className={cx('form-control', styles.container, className)} {...rest} />
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
