import { request } from '@/utils/request.util';

export type SignUpType = {
  emailOrPhone: string;
  password: string;
  firstName: string;
  lastName: string;
};

export const signUp = async (signUpData: SignUpType) => {
  return request({ url: '/auth/signup', method: 'POST', data: { ...signUpData } });
};

export const signIn = async (emailOrPhone: string, password: string) => {
  return request({ url: '/auth/signin', method: 'POST', data: { emailOrPhone, password } });
};

export const logout = async () => {
  return request({ url: '/auth/singout' });
};

export const getCurrentUser = async () => {
  return request({ url: '/users/me' });
};

export const verifyEmail = async (email: string, token: string) => {
  return request({ url: '/auth/verify', method: 'POST', data: { email, token } });
};

export const requestChangePassword = (email: string) => {
  return request({ url: '/auth/request-change-password', method: 'POST', data: { email } });
};

export const changePassword = ({
  email,
  token,
  password,
}: {
  email: string;
  token: string;
  password: string;
}) => {
  return request({
    url: '/auth/change-password',
    method: 'POST',
    data: { email, password, token },
  });
};
