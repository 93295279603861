import cx from 'classnames';
import React, { forwardRef, LegacyRef } from 'react';

// rest props is passed to native select element -> props should extend all attributes from native select element
interface SelectProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  options: { value: string; label: string }[];
}

const Select = forwardRef((props: SelectProps, ref: LegacyRef<HTMLSelectElement>) => {
  const { options, multiple, ...rest } = props;

  return (
    <select
      ref={ref}
      className={cx('form-select', { multiple })}
      aria-label="Default select example"
      multiple={multiple}
      {...rest}
    >
      {options?.map((item, index) => {
        return (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        );
      })}
    </select>
  );
});

Select.displayName = 'Select';

export default Select;
