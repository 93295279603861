import { AxiosRequestConfig } from 'axios';
import QueryString from 'qs';

import { axios } from './axios.util';
import { configParams } from './configParams';

const API_HOST = configParams.API_HOST || '';
export type RequestParams = AxiosRequestConfig;
const baseUrl = '';
const getAuthorizationBearer = () => {
  if (typeof window !== 'undefined') {
    const token = window.localStorage.getItem('ACCESS_TOKEN');
    return {
      Authorization: `Bearer ${token}`,
    };
  }
  return undefined;
};

export const request = async ({
  url = '',
  method = 'GET',
  params = {},
  data,
  headers = {},
  ...props
}: RequestParams) => {
  const fixedParams = Object.keys(params).reduce((obj, key) => {
    if (params[key] !== undefined) {
      obj[key] = params[key];
    }
    return obj;
  }, {} as any);
  // eslint-disable-next-line no-useless-catch
  //TODO update api verision
  let apiVersionUrl = ``;
  if (API_HOST.endsWith('/')) {
    apiVersionUrl = ``;
  }
  if (!url.startsWith('https://')) {
    url = `${API_HOST}${apiVersionUrl}${url}`;
  }

  try {
    const result = await axios({
      url: `${baseUrl}${url}`,
      method,
      data,
      params: fixedParams,
      paramsSerializer(params) {
        return QueryString.stringify(params, { arrayFormat: 'repeat' });
      },
      headers: {
        // Accept: 'application/json',
        // 'Content-Type': 'application/json',
        ...getAuthorizationBearer(),
        ...headers,
      },
      ...props,
    });
    return result;
  } catch (err: any) {
    throw err;
  }
};
