import { FC } from 'react';
import { ToastContainer } from 'react-toastify';

import Footer from '@/components/footer';

import Navigation from '../navigation';
import styles from './layout-ticker-purchaser.module.scss';

interface Props {
  children?: React.ReactNode;
}

const CommonLayout: FC<Props> = (props) => {
  const { children } = props;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Navigation />
        {children}
        <ToastContainer />
        <Footer />
      </div>
    </div>
  );
};

export default CommonLayout;
