import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { getCurrentUser } from '@/services/auth.services';
import { request } from '@/utils/request.util';

export interface AuthUser {
  id: string;
  emailOrPhone: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  updateAt: string;
}

type AuthStatus = 'NOT_CHECK' | 'WAITING_CHECK' | 'LOGGED_ERROR' | 'LOGGED_IN' | 'NOT_LOGGED_IN';

type PageType = 'PUBLIC' | 'PRIVATE';
interface VerifyAuthResult {
  accessToken: string;
  status: AuthStatus;
  user: AuthUser | undefined;
}

interface AuthContext {
  user: AuthUser | undefined;
  status: AuthStatus;
  verifyAuth: () => Promise<VerifyAuthResult>;
  loginWithPassword: () => void;
  logout: () => void;
  pageType: PageType | null;
  errorMessage?: string;
}

// Provider hook that creates auth object and handles state
const useProvideAuth = (): AuthContext => {
  const router = useRouter();
  const [user, setUser] = useState<AuthUser | undefined>();
  const [status, setStatus] = useState<AuthStatus>('NOT_CHECK');
  const [pageType, setPageType] = useState<PageType | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    const isPublicPage = !router.pathname.startsWith('/dashboard');
    setPageType(isPublicPage ? 'PUBLIC' : 'PRIVATE');
  }, [router]);

  useEffect(() => {
    if (status === 'LOGGED_ERROR') {
      router.push('/error');
    }
  }, [status]);

  const logout = async () => {
    try {
      await request({ url: '/auth/signout' });
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem('ACCESS_TOKEN');
      }
      router.push('/signin');
    } catch (error) {}
  };

  const loginWithPassword = async () => {
    router.push('/signin');
  };

  const getCurrentUserAuth = async (): Promise<AuthUser | undefined> => {
    try {
      const resCurrentUser = await getCurrentUser();
      return resCurrentUser?.data?.user;
    } catch (e) {
      // TODO handle
      console.error(e);
    }
  };

  const verifyAuth = async () => {
    let token: string | null = null;
    if (typeof window !== 'undefined') {
      token = window.localStorage.getItem('ACCESS_TOKEN');
    }

    const result: VerifyAuthResult = {
      accessToken: token || '',
      user: undefined,
      status: 'NOT_CHECK',
    };

    const currentUser = await getCurrentUserAuth();
    if (token && currentUser) {
      result.user = currentUser;
      result.status = 'LOGGED_IN';
    } else {
      result.user = undefined;
      result.status = 'NOT_LOGGED_IN';
      setErrorMessage('Not Auth');
    }

    setUser(result.user);
    setStatus(result.status);
    return result;
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    if (status === 'NOT_CHECK') {
      verifyAuth();
      return;
    }
    if (status === 'NOT_LOGGED_IN' && pageType === 'PRIVATE') {
      loginWithPassword();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, status, pageType]);

  return {
    status,
    user,
    verifyAuth,
    loginWithPassword,
    logout,
    pageType,
    errorMessage,
  };
};

const authContext = createContext({} as AuthContext);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: { children: React.ReactNode }) {
  const auth: AuthContext = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};
