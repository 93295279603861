import cx from 'classnames';
import * as React from 'react';

import styles from './button.module.scss';

export type HTMLButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'prefix' | 'type'
>;

export type ButtonSize = 'large' | 'medium' | 'small';
export type ButtonType = 'primary' | 'secondary' | 'link';
export type ButtonShape = 'default' | 'circle' | 'round' | 'square';
export type ButtonBorder = 'solid' | 'dashed';

export type ButtonState = 'Danger' | 'Success' | 'Default';

export interface ButtonProps extends HTMLButtonProps {
  type?: ButtonType;
  size?: ButtonSize;
  shape?: ButtonShape;
  border?: ButtonBorder;
  outline?: boolean;
  isBlock?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  state?: ButtonState;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  htmlType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

const Button = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>(
  (props, ref) => {
    const {
      children,
      type = 'primary',
      size = 'medium',
      shape = 'default',
      isBlock = false,
      isLoading = false,
      isDisabled = false,
      border,
      outline,
      prefix,
      suffix,
      state = 'Default',
      className,
      htmlType = 'button',
      onClick = () => {
        //
      },
      ...rest
    } = props;

    const success = state === 'Success';
    const danger = state === 'Danger';

    return (
      <button
        ref={ref}
        type={htmlType}
        className={cx(
          className,
          styles.ewizBtn,
          'btn',
          { [`btn${outline ? '-outline' : ''}-danger`]: danger && type !== 'link' },
          { [`btn${outline ? '-outline' : ''}-success`]: success && type !== 'link' },
          { 'text-danger': danger && type === 'link' },
          { 'text-success': success && type === 'link' },
          { 'shadow-none': type === 'link' },
          { [`btn${outline ? '-outline' : ''}-${type}`]: type },
          { 'btn-lg': size === 'large' },
          { 'btn-sm': size === 'small' },
          { 'btn-square': shape === 'square' },
          { 'rounded-circle': shape === 'circle' },
          { 'rounded-pill': shape === 'round' },
          { 'container-fluid': isBlock },
          { 'border-dashed': border === 'dashed' }
        )}
        disabled={isDisabled || isLoading}
        onClick={onClick}
        {...rest}
      >
        {prefix && !isLoading ? (
          <span className={cx({ 'me-2': !['circle', 'square'].includes(shape) })}>{prefix}</span>
        ) : null}
        {isLoading ? (
          <span
            className={cx('spinner-border spinner-border-sm', {
              'me-2': !['circle', 'square'].includes(shape),
            })}
            role="status"
            aria-hidden="true"
          ></span>
        ) : null}
        {['circle', 'square'].includes(shape) && isLoading ? null : children}
        {suffix ? <span className="ms-2">{suffix}</span> : null}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
