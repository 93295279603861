import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'antd';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import Button from '@/components/button';
import TextInput from '@/components/text-input';
import { useAuth } from '@/hooks/useAuth';

import styles from './navigation.module.scss';

const Navigation: FC = () => {
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const [needCloseMobileMenu, setNeedCloseMobileMenu] = useState(false);
  const { width } = useWindowSize();
  const { user, logout } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (width < 1024) {
      if (needCloseMobileMenu) {
        setNeedCloseMobileMenu(false);
        setIsMobileMenuActive(false);
      }
    } else {
      setNeedCloseMobileMenu(true);
      if (isMobileMenuActive) {
        setIsMobileMenuActive(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    const body = document.body;
    if (!body) {
      return;
    }

    if (isMobileMenuActive) {
      body.classList.add('lock-scroll');
    } else {
      body.classList.remove('lock-scroll');
    }
  }, [isMobileMenuActive]);

  const signUp = () => {
    router.push('/signup');
  };

  const signIn = () => {
    router.push('/signin');
  };
  const menuItem = [
    { label: 'Dashboard', onClick: () => router.push('/dashboard') },
    // { label: 'My Tickets', onClick: () => router.push('/ticket-purchaser/my-tickets') },
    { label: 'Logout', onClick: logout },
  ];

  const renderItems = () => {
    return menuItem.filter(Boolean).map((item, index: number) => ({
      key: index,
      label: (
        <div onClick={item.onClick} className={styles.label}>
          {item.label}
        </div>
      ),
    }));
  };
  return (
    <div className={classNames(styles.container, { [styles.active]: isMobileMenuActive })}>
      <div className={styles.background} />
      <div className={styles.logo}>EVENTWIZ</div>

      <div className={classNames(styles.menuItems, { [styles.active]: isMobileMenuActive })}>
        <div className={styles.search}>
          <TextInput
            prefix={<FontAwesomeIcon color="#ffffff" icon={faSearch} />}
            placeholder="Find an event, team, or artist"
            size="sm"
          />
        </div>
        <div className={styles.links}>
          <div className={styles.linkItem}>Concert</div>
          <div className={styles.linkItem}>Sports</div>
          <div className={styles.linkItem}>Arts & Theater</div>
          <div className={styles.linkItem}>Gifting</div>
          <div className={styles.linkItem}>More</div>
        </div>
        <div className={styles.auth}>
          <div className={styles.linkItem}>Sell</div>
          {user ? (
            <>
              <Dropdown
                menu={{ items: renderItems() }}
                trigger={['hover']}
                placement="bottomRight"
                overlayClassName={styles.dropdownOverlay}
              >
                <span className={styles.userName}>
                  Hi {[user.firstName, user.lastName].join(' ')}
                </span>
              </Dropdown>
            </>
          ) : (
            <>
              <Button type="secondary" onClick={signIn}>
                Login
              </Button>
              <Button onClick={signUp}>Signup</Button>
            </>
          )}
        </div>
      </div>
      <div
        className={classNames(styles.hamburgerLines, { [styles.active]: isMobileMenuActive })}
        onClick={() => setIsMobileMenuActive((val) => !val)}
      >
        <span className={classNames(styles.line, styles.line1)}></span>
        <span className={classNames(styles.line, styles.line2)}></span>
        <span className={classNames(styles.line, styles.line3)}></span>
      </div>
    </div>
  );
};

export default Navigation;
