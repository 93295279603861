import { FC } from 'react';

import Select from '@/components/select';

import styles from './footer.module.scss';

const Footer: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.background}></div>
      <div className={styles.info}>
        <div className={styles.logo}>EVENTWIZ</div>
        <div className={styles.navItems}>
          <span className={styles.item}>About Us</span>
          <span className={styles.item}>Contact Us</span>
          <span className={styles.item}>Help</span>
          <span className={styles.item}>Affiliate Program</span>
          <span className={styles.item}>Support</span>
        </div>
      </div>
      <div className={styles.locale}>
        <div className={styles.item}>
          <div className={styles.label}>Language:</div>
          <Select options={[{ value: 'English', label: 'English' }]} />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>Country:</div>
          <Select options={[{ value: 'United States', label: 'United States' }]} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
